section {
  padding: 15px 0;
}

html, body {
  overflow-x: hidden;
}

body {
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 400;
  background-color: #eee;
}

header {
  background: #48090E;
  color: #ffffff;
}
header h1 {
  font-family: Allura;
  margin-bottom: 0;
}
header h4 {
  font-family: cursive;
  padding-bottom: 10px;
}

header .header-intro {
  background: #6B4046;
  padding-bottom: 10px;
}

.timeline {
  position: relative;
}
.timeline::before {
  content: "";
  background: #B4AEBD;
  width: 5px;
  height: 95%;
  position: absolute;
  left: 5%;
  transform: translateX(-50%);
}

.timeline-item {
  width: 100%;
  margin-bottom: 45px;
}
.timeline .timeline-content {
  margin-left: 70px;
}
.timeline-item .timeline-content .date {
  right: auto;
  left: 0;
  width: 100%;
}
.timeline-item .timeline-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  left: -15px;
  border-width: 10px 15px 10px 0;
  border-color: transparent #f5f5f5 transparent transparent;
}
.timeline-item::after {
  content: "";
  display: block;
  clear: both;
}

.timeline-content {
  position: relative;
  padding: 0px 10px;
  border-radius: 4px;
  background: #f5f5f5;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
}
.timeline-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  right: -15px;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #f5f5f5;
}
.timeline-content .timeline-header {
  color: #48090E;
  font-size: x-large;
  cursor: pointer;
}

.timeline-item .btn {
  background: white;
  color: #B4AEBD;
  border-color: #B4AEBD;
}
.timeline-item .btn:hover {
  background: #B4AEBD;
  color: white;
  border-color: white;
}

.timeline-img {
  cursor: pointer;
  position: absolute;
  left: 5%;
  margin-top: 17px;
  margin-left: -27px;
}

.timeline-card {
  padding: 0 !important;
}
.timeline-card p {
  padding: 0 20px;
}
.timeline-card a {
  margin-left: 20px;
}

blockquote {
  margin-top: 30px;
  color: #757575;
  border-left-color: #3F51B5;
  padding: 0 20px;
}

.date {
  background: #382F46;
  display: inline-block;
  color: #FFFFFF;
  padding: 6px;
  cursor: pointer;
}

/* Smaller width windows */
@media screen and (max-width: 760px),
/* Phones */
only screen and (min-device-width: 375px)  and (max-device-width: 812px)  and (-webkit-min-device-pixel-ratio: 3) {
  .timeline::before {
    left: 40px;
  }
  .timeline .timeline-img {
    left: 40px;
  }
  .timeline .timeline-content {
    max-width: 100%;
    width: auto;
    margin-left: 70px;
  }
  .timeline .timeline-content {
    float: none;
  }
  .timeline .timeline-content::after {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
  }
}

.fadedScroller {
  overflow: auto;
  position: relative;
  max-height: 200px;
  margin-bottom: 0;
}
.fadedScroller a {
  color: #6B4046;
}

.fadedScroller_fade {
  content:'';
  margin-top: -70px;
  height: 70px;
  background: linear-gradient(to bottom, rgba(251, 251, 251, 0) 0%, rgba(251, 251, 251, 1) 100%);
  position: relative;
  margin-bottom: 0;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 60px;
}
.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  height: 30px;
}

.page-nav-btn {
  width: 40%;
  float: left;
}
.next-page-btn {
  float: right;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}