.kudo {
  font-size: 1rem;
  position: relative;
  border: none;
  background-color: #fff;
  padding: 0;
  width: auto;
  margin-bottom: 15px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  border-radius: 10px;
  height: auto;
}

.mask-container {
  position: relative;
}

.kudo .img-responsive {
  width: 100%;
}

.kudo .kudo-image {
  border-radius: 10px 10px 0 0;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
}
img {
  border: 0;
}

.kudo .placeholder {
  height: auto;
  min-height: auto;
  position: relative;
  text-align: center;
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.board {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 165px;
  overflow: hidden;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.kudos-container {
  padding-top: 40px;
}

.kudo .kudo-body {
  padding: 20px;
}
.kudo-body {
  padding-top: 45px;
}

.kudo .kudo-body .message, .kudo .message {
  word-wrap: break-word;
}
.kudo .message {
  font-size: 1em;
  margin-bottom: 20px;
}
.kudo .message, .p, p {
  margin: 0 0 10px;
  line-height: 1.65;
}
.kudo .kudo-footer {
  min-height: 30px;
}
.kudo .kudo-footer .byline {
  float: none;
  margin-left: auto;
  text-align: right;
}
.kudo .kudo-footer .byline {
  font-size: .875em;
  color: #899097;
  float: right;
  margin-top: 3px;
  margin-bottom: 3px;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/* Create Wish Page */
.container {
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

h1.app-page-title {
  font-weight: 700;
}

.padding-bottom-sm {
  padding-bottom: 10px;
}

.form-group {
  margin-bottom: 20px;
}
.kudo-builder .header-button {
  font-size: 12px;
  font-family: museo-sans,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}
.kudo-btn, .kudo-btn:focus {
  outline: 0;
}
.kudo-btn {
  border: none;
  background-color: transparent;
  padding: 5px 7px 0 5px;
  margin-bottom: 0;
  color: #636363;
}
.kudo .kudo-footer .buttons form, .kudo-btn {
  display: inline-block;
}
.kudo-builder .header-button i {
  margin-right: 5px;
}
.kudo-btn i {
  margin-top: -1px;
  display: inline;
}

.kb-editable {
  position: relative;
}
.kb-editable span[style] {
  font-size: inherit!important;
}
.kb-editable .kb-editable-edit-textarea .textarea-container {
  position: relative;
}
.kudo-builder .kb-editable .content-editable, .kudo-builder .kb-editable .kb-editable-view {
  background-color: #fff;
  min-height: 300px;
}
.kb-editable .content-editable {
  width: 100%;
  border: 2px solid #e4e5e8;
  white-space: pre-wrap;
  padding: 6px 12px;
  box-shadow: none;
}
.medium-editor-element {
  word-wrap: break-word;
  min-height: 30px;
}
.margin-bottom-sm {
  margin-bottom: 10px;
}

.floating-button {
  background: #fff;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 40%);
  border-radius: 24px;
  color: #3a4651;
  font-weight: 700;
  padding: 7px 10px;
  position: absolute;
  right: 0;
  top: -20px;
  transition: color .2s ease;
  margin-right: 10px;
}
.clickable {
  cursor: pointer;
}

.button-wrapper {
  margin: 0 auto;
  max-width: 900px;
  position: relative;
}
.board-header {
  text-align: center;
  padding: 100px 0 0;
  position: relative;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}
.board-buttons {
  min-height: 42px;
}
.kb-sticky {
  transform: translateZ(0);
}
.board-header-content {
  z-index: 2;
}

@media (min-width: 768px) {
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    float: left;
  }
  .board {
    overflow: visible;
    padding-bottom: 40px;
  }
  .kudos-container {
    max-width: 1700px;
    margin-top: 0;
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 55px;
  }
  .kudo .kudo-body.has-media {
    padding-top: 15px;
  }
  .kudo .kudo-body {
    padding: 35px 15px 15px;
  }
  .kudo .message, .kudo .message blockquote {
    font-size: .8125em;
  }
  .kudo .kudo-footer .byline {
    font-size: .6875em;
  }

  /* Create Wish Page */
  .container {
    width: 750px;
  }
}